 import Navbar from './components/navigation/navbar/NavbarView';
import PageNotFound from './components/navigation/PageNotFound';
import Banner from './utility/notifications/Banner';
import FooterView from './components/footer/FooterView';
import EditAccount from './components/auth/EditAccount';
import ChangePassword from './components/auth/ChangePassword';
import EditResidents from './components/auth/EditResidents';
import Landing  from './components/landing/LandingView';
import ProductList from './components/products/productList/ProductListView';
import ProductListTEST from './components/products/productList/ProductListViewTEST';
import ProductDetail from './components/products/productDetail/ProductDetail';
import AdminRoute from './utility/routing/AdminRoute';
import PublicRoute from './utility/routing/PublicRoute';
import PrivateRoute from './utility/routing/PrivateRoute';
import CartList from './components/checkout/CartList';
import CartListTEST from './components/checkout/CartListTEST';
import Authentication from './components/auth/authentication';
import InvoiceList from './components/auth/InvoiceList';
import InvoicePDF from './components/auth/InvoicePDF';
import AccountDashboard from './components/auth/AccountDashboard';
import SizeGuide from './components/info/sizing/SizingView';
import FreeCatalog from './components/info/freeCatalog/FreeCatalogView';
import Contact from './components/info/contact/ContactView';
import About from './components/info/about/AboutView';
import PrivacyPolicy from './components/info/policy/PrivacyView';
import FacilityForms from './components/info/facForms/FacFormsView';
import QualityGuarantee from './components/info/qualityGuarantee/QualityGuaranteeView';
import ReturnPolicy from './components/info/policy/ReturnsView';
import GiftCardPromo from './components/info/giftCard/GiftCardView';
import FAQ from './components/info/faq/FAQView';
import Shipping from './components/info/shipping/ShippingView';
import Labeling from './components/info/labeling/LabelingView';
import Feedback from './components/info/feedback/FeedbackView';
import HelpCenter from './components/auth/helpCenter';
import PostCheckout from './components/checkout/PostCheckout';
import FacilityComplete from './components/checkout/FacilityComplete';
import FacilityPromo from './components/auth/FacilityPromo';
import LoginAdmin from './components/admin/AdminLogin';
import Dashboard from './components/admin/Dashboard';
import AdminBanner from './components/admin/AdminBanner';
import RewardsInfo from './components/auth/Rewards';
import ReferralSubmission from './components/auth/ReferralSubmission';
import handleReCAPTCHA from './utility/google/handleReCAPTCHA';
import {RecaptchaContext} from './context/recaptcha';
import {useStore} from '../store';
import {authService} from '../utility';
import './styling/index.min.css';
import React, {useEffect, useRef} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import reactGA from './utility/google/reactGA';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

const theme = createTheme({
  palette: {
    primary: {
      main: '#444444'
    }
  }
});

const App = () => {
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const dispatchLogin = useStore((state) => state.dispatchLogin);
  const location = useLocation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  
  const checkStoredCredentials = async () => {
    if (isAuthenticated || location.pathname.includes('/admin')) return;
    const credentials = authService.credentials.get();
    
    if (credentials) {
      const token = await handleReCAPTCHA(recaptchaRef);
      if (!token) return;
      
      dispatchLogin(credentials.username, credentials.password, token, true);
    }
  };

  useEffect(() => {
    checkStoredCredentials();
    reactGA.init();
  }, []);
  
  // useEffect(() => {
  //   history.push('/');
  // }, [dispatchLogout]);
  
  return (
    <RecaptchaContext.Provider value={recaptchaRef}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>

          <div className='app'>
            <Switch>
              <Route exact path='/invoice/view/:userId/:invoiceId' component={InvoicePDF} />

              <Route path='/admin'>
                <AdminBanner />
                <Switch>
                  <PublicRoute
                    title='Admin: Login'
                    exact
                    path='/admin'
                    component={LoginAdmin}
                  />
                  <AdminRoute
                    title='Admin: Dashboard'
                    component={Dashboard}
                  />
                </Switch>
              </Route>

              <Route>
                <Banner />
                <Navbar />
                <Switch>
                  <PublicRoute
                    title='Login'
                    exact
                    path='/login'
                    component={Authentication}
                  />
                  <PublicRoute
                    title='Register'
                    exact
                    path='/register'
                    component={Authentication}
                  />
                  <PrivateRoute
                    title='Account'
                    path='/account'
                    component={AccountDashboard}
                  />
                  <PublicRoute
                    title='Product:'
                    exact
                    path='/productDetail'
                    component={ProductDetail}
                  />
                  <PublicRoute
                    title='Catalog: Browse'
                    exact
                    path='/productList/dept/:dept/:subDept?/:superSubDept?'
                    component={ProductListTEST}
                  />
                  <PublicRoute
                    title='Catalog: Search'
                    exact
                    path='/productList/search/:search'
                    component={ProductListTEST}
                  />
                  <PublicRoute
                    title='Catalog: Search'
                    exact
                    path='/productList/search/'
                    component={ProductListTEST}
                  />
                  <PublicRoute
                    title='Catalog: Needs'
                    exact
                    path='/productList/need/:need'
                    component={ProductListTEST}
                  />
                  <PublicRoute
                    title='Checkout'
                    exact
                    path='/checkout/carts'
                    component={CartListTEST}
                  />
                  <PublicRoute
                    title='Checkout'
                    exact
                    path='/checkout/carts/test'
                    component={CartListTEST}
                  />
                  <PublicRoute
                    title='Post Checkout'
                    exact
                    path='/checkout/post'
                    component={PostCheckout}
                  />
                  <PublicRoute
                    title='Facility Post Checkout'
                    exact
                    path='/checkout/complete/facility'
                    component={FacilityComplete}
                  /> 
                  <PublicRoute
                    title='Size Guide'
                    exact
                    path='/info/sizeGuide'
                    component={SizeGuide}
                  />
                  <PublicRoute
                    title='Free Catalog'
                    exact
                    path='/info/freeCatalog'
                    component={FreeCatalog}
                  />
                  <PublicRoute
                    title='Feedback'
                    exact
                    path='/info/feedback'
                    component={Feedback}
                  />
                  <PublicRoute
                    title='Contact'
                    exact
                    path='/info/contact'
                    component={Contact}
                  />
                  <PublicRoute
                    title='About'
                    exact
                    path='/info/about'
                    component={About}
                  />
                  <PublicRoute
                    title='Facility Forms'
                    exact
                    path='/info/facilityForms'
                    component={FacilityForms}
                  />
                  <PublicRoute
                    title='Quality Guarantee'
                    exact
                    path='/info/qualityGuarantee'
                    component={QualityGuarantee}
                  />
                  <PublicRoute
                    title='Return Policy'
                    exact
                    path='/info/returnPolicy'
                    component={ReturnPolicy}
                  />
                  <PublicRoute
                    title='Gift Card Promo'
                    exact
                    path='/info/giftCards'
                    component={GiftCardPromo}
                  />
                  <PublicRoute
                  title='Rewards Info'
                  exact
                  path='/info/rewards'
                  component={RewardsInfo}
                  />
                <PublicRoute
                    title='Labeling Info'
                    exact
                    path='/info/labeling'
                    component={Labeling}
                  />
                  <PublicRoute
                    title='Facility Info'
                    exact
                    path='/info/facility'
                    component={FacilityPromo}
                  />
                  <PublicRoute
                    title='FAQ'
                    exact
                    path='/info/faq'
                    component={FAQ}
                  />
                  <PublicRoute
                    title='Shipping Info'
                    exact
                    path='/info/shipping'
                    component={Shipping}
                  />
                  <PublicRoute
                    title='Privacy Policy'
                    exact
                    path='/info/privacy'
                    component={PrivacyPolicy}
                  />
                  <PublicRoute
                    title='Refer a Facility'
                    exact
                    path='/referFacility'
                    component={ReferralSubmission}
                  />
                  <PublicRoute
                    title='Help Center'
                    exact
                    path='/helpCenter/:type/:token?'
                    component={HelpCenter}
                  />
                  <PublicRoute
                    title='Landing'
                    exact
                    path='/'
                    component={Landing}
                  />
                  <PublicRoute
                    title='404'
                    component={PageNotFound}
                  />
                </Switch>
                <FooterView />
              </Route>

            </Switch>
            <ReCAPTCHA
              className='recaptcha'
              ref={recaptchaRef}
              size='invisible'
              sitekey={authService.recaptcha.siteKey}
            />
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </RecaptchaContext.Provider>
  );
}

export default App;
