import api from '../../baseApi';
import {isDebugging} from '../../../utility';
import axios, {AxiosResponse} from 'axios';

export type Request = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  catalogCount: string;
  email: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const orderCatalogPort = '/requestCatalog';

/**Submits an order for catalog(s). */
const orderCatalogAPI = async (req: Request, captchaToken: string): Promise<AxiosResponse<Response>> => {
  const res = await api.post(orderCatalogPort, req, {
    headers: {
      remoteip: '',
      captchaToken: captchaToken
    }
  });

  if (isDebugging) {
    console.log(`${orderCatalogPort} response:`, res);//TESTING
  }

  return res;
};

export default orderCatalogAPI;