import React, { useState, ChangeEvent, FormEvent } from 'react'
import { TextField, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import {CountryData, Region} from 'country-region-data';
import SelectRegion from 'application/ui/utility/location/SelectRegion';
import SelectCountry from 'application/ui/utility/location/SelectCountry';
// import asideImg from 'application/ui/media/happy_woman_laptop.jpg';
import asideImg from 'application/ui/media/SSS_referral_image.jpg';
import {ReactComponent as LoadingIconSmall} from 'application/ui/media/icon-loading-small.svg';
import { sendEmailAPI } from 'application/api';
import { useStore } from 'application/store';
import { useHistory } from 'react-router-dom';

type SubmitStage = 'none' | 'submitting' | 'submitted';

const ReferralSubmission = () => {
  const history = useHistory();
  const userId = useStore((state) => state.account?.userId)
  const facPo = useStore((state) => state.account?.company?.po)
  const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
  const [selectedCountry, setSelectedCountry] = useState<CountryData | undefined>();
	const [selectedRegion, setSelectedRegion] = useState<Region | undefined>();
  const [submitStage, setSubmitStage] = useState<SubmitStage>('none');
  const [formData, setFormData] = useState({
    keyContact: '',
    keyContactRole: '',
    keyContactEmail: '',
    facName: '',
    address1: '',
    address2: '',
    city: '',
    zip: ''
  });

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(`event: ${event.target.name}: ${event.target.value}`)
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitStage('submitting');

    console.log(`formData: ${formData.keyContactEmail}`)

    let request = {
      to: 'bpenticuff@seniorshoppingservice.com',
      subject: 'Referral Submission',
      body: '<h2>Facility Information:</h2>'
    };

    for (const [key, value] of Object.entries(formData)) {
      request.body = request.body.concat(`<BR>${key}: ${value}`);
    }
    request.body = request.body.concat(`<BR>state: ${selectedRegion ? selectedRegion[1] : 'N/A'}`)
    request.body = request.body.concat(`<BR><h3>Referring Facility User ID: ${userId}</h3>`)
    request.body = request.body.concat(`<BR><h3>Referring Facility PO: ${facPo || 'N/A'}</h3>`)

    console.log(`body: ${request.body}`)

    try {
      await sendEmailAPI(request);
      
    } catch (error: any) {
      dispatchSetAlert({
        status: 'danger',
        header: 'Error',
        text: 'We were unable to complete the request, please try again later'
      })
    }

    setSubmitStage('submitted');
  };

  const onContinueShopping = () => {
    history.push(`/productList/dept/1`);
  }

  if (submitStage === 'submitted') {
    return (
      <section className='refer-fac-submitted'>
        <h1>Thank you! Your facility referral has been submitted. Our team will contact them shortly.</h1>
        <button
          className='btn btn--primary'
          onClick={onContinueShopping}
        >
          Continue Shopping
        </button>
      </section>
    )
  }

  return (
    <section className='refer-fac'>
      <main>
        <h1>Refer A Facility</h1>
        <p>
          Exciting news,  You can share the benefits of our services with other facilities and get rewarded!
        </p>
        <p>
          When you refer another facility to us, and they place their first order, <b>both you and the referred facility will receive a free Bingo Box Kit, and $25 in Rewards Dollars</b> - a fun and engaging activity and reward dollars for your residents that is typically valued at $84.99!
        </p>
        <p>
          It’s our way of saying thank you for spreading the word and helping us grow our community. Start sharing today and enjoy this special reward together!
        </p>
        <form action='Submit' onSubmit={submitForm}>
          <TextField
            name='keyContact'
            placeholder='Key Contact'
            value={formData.keyContact}
            onChange={onChangeInput}
            required
          />
          {/* <TextField
            name='keyContactRole'
            placeholder="Key Contact's Role"
            value={formData.keyContactRole}
            onChange={onChangeInput}
            required
          /> */}
          <FormControl>
            <InputLabel id='contact-role'>Key Contact's Role</InputLabel>
            <Select
              labelId='contact-role'
              label="Key Contact's Role"
              name='keyContactRole'
              value={formData.keyContactRole}
              onChange={(e) => setFormData({...formData, keyContactRole: e.target.value})}
              required
            >
              <MenuItem value='Administration'>Administration</MenuItem>
              <MenuItem value='Business Office Staff'>Business Office Staff</MenuItem>
              <MenuItem value='Social Services'>Social Services</MenuItem>
              <MenuItem value='Activities/Recreation'>Activities/Recreation</MenuItem>
              <MenuItem value='Central Supply/Housekeeping'>Central Supply/Housekeeping</MenuItem>
              <MenuItem value='Laundry'>Laundry</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name='keyContactEmail'
            placeholder="Key Contact's Email"
            value={formData.keyContactEmail}
            onChange={onChangeInput}
            required
          />
          <TextField
            name='facName'
            placeholder='Facility Name'
            value={formData.facName}
            onChange={onChangeInput}
            required
          />
          <TextField
            name='address1'
            placeholder='Address'
            value={formData.address1}
            onChange={onChangeInput}
            required
          />
          <TextField
            name='address2'
            placeholder='Apt. or Ext.'
            value={formData.address2}
            onChange={onChangeInput}
          />
          <TextField
            name='city'
            placeholder='City'
            value={formData.city}
            onChange={onChangeInput}
            required
          />
          <SelectRegion
            selectedCountry={selectedCountry}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
          />
          <TextField
            type='number'
            name='zip'
            placeholder='Zip Code'
            value={formData.zip}
            onChange={onChangeInput}
            required
          />
          <SelectCountry
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
          <button
            className='btn btn--primary form__submit'
            type='submit'
            disabled={submitStage === 'submitting'}
          >
            {
              submitStage === 'submitting' ?
              <LoadingIconSmall /> :
              'Submit'
            }
          </button>
        </form>
      </main>
      <picture>
        <img src={asideImg} alt='happy woman looking at a laptop'/>
      </picture>
    </section>
  )
}

export default ReferralSubmission