import {post} from 'application/api/baseApi';
import axios from 'axios';

type Request = {
  to: string;
  subject: string;
  body: string;
};

type Response = {
  status: 'SUCCESS' | 'FAILURE';
  message: string;
};

const path = "https://prod-134.westus.logic.azure.com:443/workflows/86d77e4523834e0c9ca094768f390c2a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=LFkLbFnGPiN19rZNgkq1PpRw_qyJkM7GL90fRXpdJpQ";


// Creating new instance, since the path above is to a new server
const newInstance = axios.create({
	headers: {
		'Content-Type': 'application/json'
	}
});

const sendEmailAPI = (req: Request) => post<Response>(path, {...req, attachment: ''}, newInstance);

export default sendEmailAPI;